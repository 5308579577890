.profile-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  font-size: 19px;
  text-align: center;
  color: white;

  .profile-topbar {
    padding: 10px 10px 10px 5px;
    width: 100%;
    height: 80px;

    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: top;

    .profile-topbar-item {
      width: 26px;
      height: 26px;

      font-size: 14px;

      background-size: contain;
      background-repeat: no-repeat;
      margin: auto;

      &.back {
        margin-right: 50%;
      }
    }
  }

  .profile-scrollable {
    overflow-y: auto;

    .profile-user {
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 15px;

      .name {
        font-weight: 600;
      }

      .handle {
        font-size: 17px;
        font-weight: 600;
      }

      .image {
        width: 160px;
        height: 160px;
        border-radius: 50%;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
        background-color: #333;

        background-size: contain;
        background-repeat: no-repeat;
      }

      .info {
        padding-top: 30px;
        font-size: 14px;

        display: flex;
        flex-direction: row;
        gap: 15px;
        align-items: center;
        justify-content: center;
      }
    }

    .profile-feed {
      padding-top: 40px;
      padding-bottom: 40px;
      height: auto;

      &.loading {
        height: 50%;
      }

      .profile-feed-title {
        padding-bottom: 20px;
        font-weight: 600;
      }

      .profile-feed-items {
        display: flex;
        flex-direction: row;
        gap: 3px;
        justify-content: space-between;
        flex-wrap: wrap;

        .profile-feed-item {
          width: calc(33% - 1px);
          height: 160px;
          background-color: #333;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: 50%;

          .info {
            width: calc(100% - 0px);
            margin: calc(100% - 14px) 0px 0px 0px;
            padding: 3px 5px;
            background-color: rgba(0, 0, 0, 0.5);

            font-size: 10px;
            text-align: left;

            display: flex;
            flex-direction: row;
            gap: 0;
            justify-content: space-between;
            flex-wrap: wrap;

            .info-item {
              width: calc(50% - 5px);
            }

            .gem {
              width: 100%;
              font-size: 12px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
