@import '../../../media-queries.scss';
@import '../_variables.scss';

.top-bar * {
  pointer-events: auto;
}

.top-bar {
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  // height: $top-navvar-height;
  z-index: 3;

  // background-color: rgba(0, 0, 0, 0.2);
  // -webkit-backdrop-filter: blur(33px);
  // backdrop-filter: blur(33px);
  // background-color: black;

  // padding: 10px; // 10px 25px 10px;

  display: flex;
  flex-direction: row;
  gap: 14%;
  align-items: center;
  justify-content: center;

  .navbar-items {
    width: 100%;
    max-width: 600px;

    display: flex;
    flex-direction: row;
    gap: 13%;
    align-items: center;
    justify-content: left;

    padding: 10px;

    .navbar-item {
      font-size: 12px;
      font-weight: 600;
      line-height: 13px;
      text-decoration: none;
      text-align: center;
      color: white;

      display: flex;
      flex-direction: row;
      gap: 8px;

      &.ethereum {
        position: absolute;
        left: 10px;
        top: 5px;
      }

      &.search {
        position: absolute;
        right: 10px;
        top: 5px;
      }

      .navbar-item-icon {
        background-size: contain;
        background-repeat: no-repeat;
        width: 26px;
        height: 26px;
        margin: auto;
      }
      .navbar-item-label {
        // height: fit-content;
        height: 26px;
        text-align: center;
        margin-top: 15px;
      }
    }
  }
}

// @media (max-width: 699px) {
//   .top-bar {
//     // padding: 10px 10px 25px 10px;

//     .navbar-items {
//       .navbar-item {
//         .navbar-item-icon {
//           width: 20px;
//           height: 20px;
//         }
//       }
//     }
//   }
// }
