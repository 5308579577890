@import '../../../media-queries.scss';
@import '../_variables.scss';

.bottom-navbar-create * {
  pointer-events: auto;
}

.bottom-navbar-create {
  pointer-events: none;
  position: fixed;
  bottom: 0px;

  width: 100%;
  height: $bottom-navvar-height;
  z-index: 3;

  max-width: 414px;
  left: calc(50% - 207px);
  @media (max-width: 413px) {
    left: 0;
  }

  background-color: black;

  padding: 10px;

  display: flex;
  flex-direction: row;
  gap: 14%;
  align-items: center;
  justify-content: center;

  .navbar-items {
    width: 100%;
    max-width: 600px;

    display: flex;
    flex-direction: row;
    gap: 5.5%;
    align-items: top;
    justify-content: left;

    padding: 10px;

    .navbar-separator {
      width: 1px;
      height: 50px;
      background-color: #ffffff;
      opacity: 0.5;
    }

    .navbar-item {
      font-size: 10px;
      font-weight: 600;
      line-height: 13px;
      text-decoration: none;
      text-align: center;
      color: white;

      display: flex;
      flex-direction: column;

      .navbar-item-icon {
        background-size: contain;
        background-repeat: no-repeat;
        width: 26px;
        height: 26px;
        margin: auto;
      }
      .navbar-item-label {
        height: fit-content;
      }

      &.ai-progress {
        .progress-title {
          padding-top: 2px;
          font-size: 15px;
          text-align: left;

          .progress-subtitle {
            padding-top: 5px;
            font-size: 12px;
            text-align: left;
            color: #ccc;
            line-height: 16px;
            font-weight: 300;
          }
        }

        .progress-bar {
          width: 305px;
          height: 6px;
          border-radius: 5px;
          background-color: #ffffff;
          margin-top: 6px;
        }
      }
    }
  }
}

@media (max-width: 699px) {
  .bottom-navbar {
    padding: 10px 10px 25px 10px;

    .navbar-items {
      .navbar-item {
        .navbar-item-icon {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}
